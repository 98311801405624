
function Agreement() {

  
  return (<>
Пользовательское соглашение
  
    </>
  );
}

export default Agreement;
