const config = {
  rootUrl: "https://srm.logistic-hub.com",
  crmUrl: "https://srm.logistic-hub.com",
  siteUrl: "https://logistic-hub.com",
  serverUrl: "https://server.logistic-hub.com",
  //rootUrl: "localhost:3000/development",

  //rootUrl: "http://logistic-hub/development",

  reCapchaKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
};

export default config;


