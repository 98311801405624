import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import RouteIcon from "@mui/icons-material/Route";
import { useTranslation } from "react-i18next";


const menuLinks = [
  {
    text: "dashboard",
    to: "/dashboard",
    icon: <RouteIcon color="white" />,
  },
  {
    text: "pages.assets",
    icon: <PeopleAltOutlinedIcon color="white" />,
    expand: [
      { text: "pages.drivers", to: "/assets/drivers" },
      { text: "pages.vehicles", to: "/assets/vehicles" },
      { text: "pages.trailers", to: "/assets/trailers" },
    ],
  },
  
];



export default menuLinks;
