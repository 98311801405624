import { useState, useEffect } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import axios from "axios";
import config from "../../config";
import DriverCard from "../../components/Assets/DriverCard";
import AddNewCard from "../../components/Cards/AddNewCard";
import { Link } from "react-router-dom";
import { Box, Pagination, Stack } from "@mui/material";
import { useUI } from "../../context/CustomIUProvider";

import Header from "../../components/Layouts/Header/Header";
import SideMenu from "../../components/Menu/SideMenu/SideMenu";
import StatusFilter from "../../components/Assets/StatusFilter";


function Drivers() {
  const { t } = useTranslation();
  const { showSnackbar } = useUI();

  const [page, setPage] = useState(1);
  const itemsPerPage = 5;
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [currentDrivers, setCurrenDrivers] = useState([]);

  const auth = useAuthUser();
  console.log(auth);
  const company_id = auth.company_id;
  const authToken = useAuthHeader();

  const [drivers, setDrivers] = useState([]);
  const [statuses, setStatuses] = useState([0, 0, 0]);

  const links = ["drivers", "vehicles", "trailers"];

  useEffect(() => {
    setCurrenDrivers(
      drivers.slice((page - 1) * itemsPerPage, page * itemsPerPage)
    );
  }, [page]);

  useEffect(() => {
    const headers = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `${config.serverUrl}/axios/cards/fetchAssets.php`,
        { company_id: company_id, assets: "drivers" },
        headers
      )
      .then((response) => {
        const data = response.data;
        setDrivers(data);
        setCurrenDrivers(
          data.slice((page - 1) * itemsPerPage, page * itemsPerPage)
        );

        setStatuses([
          data.filter((item) => item.driverStatus === "1").length,
          data.filter((item) => item.driverStatus === "2").length,
          data.filter((item) => item.driverStatus === "3").length,
        ]);
      })
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            showSnackbar(t(error.response.data.message), "error");
          } else if (error.response.status === 500) {
            showSnackbar(t("errors.server"), "error");
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      });
  }, [company_id]);

  //const getFilteredData = () => {
  //  if (activeFilters.includes(0)) {
  //    return drivers;
  //  }
  //  return drivers.filter((item) =>
  //    activeFilters.includes(Number(item.driverStatus))
  //  );
  //};

  return (
    <>
      <SideMenu />
      <Grid
        container
        sx={{
          width: "100%",
          position: "relative",
          my: 2,
          mx: 2,
          p: 2,
          borderRadius: "14px",
          background: "#F5F5F5",
          overflow: "auto",
        }}
      >
        <Grid container xs={12} direction="column" sx={{ my: 2 }}>
          <Header
            title={t("pages.assets")}
            activeLink={0}
            links={links}
            prefix="/assets"
          />

          <Box>
            <StatusFilter type="drivers" statuses={statuses} />
            <Grid container>
              {currentDrivers.map((driver) => (
                <DriverCard data={driver} key={driver.driver_id} />
              ))}
              <Link to="/assets/new-driver">
                <AddNewCard item="driver" />
              </Link>
            </Grid>
          </Box>
          <Stack spacing={2} alignItems="center" mt={2}>
            <Pagination
              count={Math.ceil(drivers.length / itemsPerPage)}
              page={page}
              onChange={handleChange}
              color="primary"
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
export default Drivers;
