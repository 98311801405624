import {
  Stepper,
  StepButton,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";
import SideMenu from "../../components/Menu/SideMenu/SideMenu";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { styled } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import { useState } from "react";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0E1C36",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#0E1C36"    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "transparante",
  zIndex: 1,
  color: "red",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid tranparante",

  ...(ownerState.active && {
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    border: "1px solid #0E1C36",
    color: "#0E1C36",
  }),
  ...(ownerState.completed && {
    color: "#0E1C36",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
    4: <GroupAddIcon />
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function AddShipping() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Маршрут", "Вантаж", "Транспорт", "Документи"];
  const [completed, setCompleted] = useState({});

  const handleStep = (index) => {
    setActiveStep(index);
    setCompleted((prevCompleted) => {
      const newCompleted = {};
      for (let i = 0; i <= index; i++) {
        newCompleted[i] = true;
      }
      return newCompleted;
    });
  };

  return (
    <>
      <SideMenu />
      <Grid
        container
        sx={{
          width: "100%",
          position: "relative",
          my: 2,
          mx: 2,
          p: 2,
          borderRadius: "14px",
          background: "#F5F5F5",
          overflow: "auto",
        }}
      >
        <Stepper
        nonLinear
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label, index) => (
            <Step key={index} completed={completed[index]}>
              <StepButton color="inherit" onClick={() => handleStep(index)}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </>
  );
}

export default AddShipping;
