import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";




function Shippings (){

return(<>
    <Typography variant="h1">Shippings</Typography>
<Button component={Link} to="/new-shipping">new shipping</Button>
</>
)
}

export default Shippings;