import { useState, useEffect } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import axios from "axios";
import config from "../../config";
import TrailerCard from "../../components/Cards/TrailerCard";
import AddNewCard from "../../components/Cards/AddNewCard";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { useUI } from "../../context/CustomIUProvider";
import FilterChip from "../../components/General/FilterChip";
import Header from "../../components/Layouts/Header/Header";

import { ReactComponent as VehicleIcon } from "../../assets/icons/vehicle.svg";
import { ReactComponent as TruckSpeedIcon } from "../../assets/icons/truck-speed.svg";
import { ReactComponent as BlankIcon } from "../../assets/icons/blank.svg";
import { ReactComponent as RestIcon } from "../../assets/icons/rest.svg";

function Trailers() {
  const { t } = useTranslation();
  const { showSnackbar } = useUI();

  const auth = useAuthUser();
  const company_id = auth.company_id;
  const authToken = useAuthHeader();

  const [trailers, setTrailers] = useState([]);
  const [statuses, setStatuses] = useState([0, 0, 0]);
  const [activeFilters, setActiveFilters] = useState([0]); // 0 represents all

  const links = ["drivers", "vehicles", "trailers"];

  useEffect(() => {
    const headers = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `${config.serverUrl}/axios/cards/fetchAssets.php`,
        { company_id: company_id, assets: "trailers" },
        headers
      )
      .then((response) => {
        const data = response.data;
        setTrailers(data);
        setStatuses([
          data.filter((item) => item.trailerStatus === "1").length,
          data.filter((item) => item.trailerStatus === "2").length,
          data.filter((item) => item.trailerStatus === "3").length,
        ]);
      })
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            showSnackbar(t(error.response.data.message), "error");
          } else if (error.response.status === 500) {
            showSnackbar(t("errors.server"), "error");
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      });
  }, [company_id]);

  const handleFilterClick = (filter) => {
    if (filter === 0) {
      setActiveFilters([0]);
    } else {
      setActiveFilters((prev) => {
        const isActive = prev.includes(filter);
        if (isActive) {
          return prev.filter((f) => f !== filter);
        } else {
          return [...prev.filter((f) => f !== 0), filter];
        }
      });
    }
  };

  const getFilteredData = () => {
    if (activeFilters.includes(0)) {
      return trailers;
    }
    return trailers.filter((item) =>
      activeFilters.includes(Number(item.trailerStatus))
    );
  };

  const chips = [
    {
      label: "total",
      icon: <VehicleIcon />,
      filter: 0,
      color: "#0E1C36",
      count: trailers.length,
    },
    {
      label: "flight",
      icon: <TruckSpeedIcon />,
      filter: 1,
      color: "#0075C4",
      count: statuses[0],
    },
    {
      label: "free",
      icon: <BlankIcon />,
      filter: 2,
      color: "#0E951B",
      count: statuses[1],
    },
    {
      label: "rest",
      icon: <RestIcon />,
      filter: 3,
      color: "#A61C3C",
      count: statuses[2],
    },
  ];

  return (
    <>
      <Grid container xs={12} direction="column" sx={{ my: 2 }}>
        <Header
          title={t("pages.assets")}
          activeLink={2}
          links={links}
          prefix="/assets"
        />
        
        <Box>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            {chips.map((chip) => {
              const active = activeFilters.includes(chip.filter);
              return (
                <Grid key={chip.filter}>
                  <FilterChip
                    onClick={() => handleFilterClick(chip.filter)}
                    count={chip.count}
                    icon={chip.icon}
                    active={active}
                    label={t("general.status." + chip.label)}
                    color={chip.color}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid container>
            {getFilteredData().map((trailer) => (
              <TrailerCard data={trailer} key={trailer.trailer_id} />
            ))}
            <Link to="/assets/new-trailer">
              <AddNewCard item="trailer" />
            </Link>
          </Grid>
        </Box>
      </Grid>
    </>
  );
}
export default Trailers;
